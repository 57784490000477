<template>
  <div class="order">
    <div class="df-row">
      <!-- <div class="df-col">
        <el-select
          v-model="submitData.business_guid"
          placeholder="企业名称"
          size="mini"
          filterable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->

      <!-- <div class="df-col">
        <el-select
          v-model="submitData.type"
          placeholder="配送方式"
          size="mini"
          @change=""
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->

      <div class="df-col">
        <el-select
          v-model="submitData.order_stores_guid"
          placeholder="订单门店名称"
          size="mini"
        >
          <el-option
            v-for="item in storeList"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div>

      <!-- <div class="df-col">
        <el-select
          v-model="submitData.take_stores_guid"
          placeholder="自提门店名称"
          size="mini"
        >
          <el-option
            v-for="item in storeList"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div> -->

      <div class="df-col">
        <el-select
          v-model="submitData.status"
          placeholder="订单状态"
          size="mini"
        >
          <el-option
            v-for="item in statusList"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <el-input
          v-model="submitData.word"
          placeholder="请输入订单号/用户昵称"
          size="mini"
          clearable
        ></el-input>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch"
          >查 询</el-button
        >
      </div>
    </div>

    <!-- start 订单表格 -->
    <div
      class="df-order-table"
      :style="'--max-height:' + tableMaxHeight + 'px'"
    >
      <!-- 表头 -->
      <div class="thead">
        <div class="tr tr-goods-info">
          <div class="th">商品信息</div>
        </div>
        <div class="tr tr-goods-price">
          <div class="th">单价/数量</div>
        </div>
        <div class="tr tr-goods-actual">
          <div class="th">实付款</div>
        </div>
        <div class="tr tr-goods-buyer">
          <div class="th">买家</div>
        </div>
        <div class="tr tr-goods-pay">
          <div class="th">支付方式</div>
        </div>
        <div class="tr tr-goods-express">
          <div class="th">配送方式</div>
        </div>
        <div class="tr tr-goods-trade">
          <div class="th">交易状态</div>
        </div>
        <div class="tr tr-goods-status">
          <div class="th">订单状态</div>
        </div>
        <div class="tr tr-goods-control">
          <div class="th">操作</div>
        </div>
      </div>

      <!-- 表身 -->
      <div v-if="orderList.length" class="tbody">
        <div
          class="tr"
          v-for="(orderItem, orderIndex) in orderList"
          :key="orderIndex"
        >
          <div class="order-base">
            <span class="order-store-name"
              >门店：{{ orderItem.StoresName }}</span
            >
            <span class="data-time">{{
              util.dateFormat(
                "YYYY-mm-dd HH:MM:SS",
                new Date(orderItem.CreateTime * 1000)
              )
            }}</span>
            <span class="order-no">订单号：{{ orderItem.OrderNo }}</span>

            <div v-if="orderItem.OrderType == 2" class="groupon-info">
              <!-- <el-tag
                v-if="orderItem.GrouponData == null"
                :type="orderItem.Status == 90 ? 'info' : 'warning'"
                size="small"
                effect="dark"
                >拼团</el-tag
              > -->
              <el-tag
                v-if="orderItem.GrouponData == null"
                type="warning"
                size="small"
                effect="dark"
                >拼团</el-tag
              >
              <el-tag
                v-else
                :type="
                  orderItem.GrouponData.Status == 1
                    ? 'warning'
                    : orderItem.GrouponData.Status == 2
                    ? 'success'
                    : 'danger'
                "
                size="small"
                effect="dark"
                @click="goto(orderItem)"
                >拼团 {{ orderItem.GrouponData.StatusText }}</el-tag
              >
            </div>
          </div>

          <div class="order-item">
            <div
              class="order-item-list"
              v-for="(item, index) in orderItem.OrderItemList"
              :key="index"
            >
              <!-- 商品信息 -->
              <div class="td tr-goods-info">
                <div class="goods-info">
                  <div class="image">
                    <el-image
                      :src="item.ItemImage"
                      fit="fill"
                      :lazy="true"
                    ></el-image>
                  </div>
                  <div class="info">
                    <span class="name">{{ item.ItemName }}</span>
                    <span v-if="item.AttrNameList" class="attr"
                      >选择的套餐：{{ item.AttrNameList }}</span
                    >
                  </div>
                </div>
              </div>

              <!-- 单价/数量 -->
              <div class="td tr-goods-price">
                <div class="goods-price">
                  <span class="price">¥ {{ item.ActualPrice }}</span>
                  <span class="count">× {{ item.ItemCount }}</span>
                </div>
              </div>
            </div>

            <!-- 实付款 -->
            <div class="td tr-goods-actual">
              <div class="goods-actual-amount">
                <span class="amount">¥ {{ orderItem.ActualAmount }}</span>
                <!-- <span class="express">(含运费：¥ 0.00)</span> -->
              </div>
            </div>

            <!-- 买家 -->
            <div class="td tr-goods-buyer">
              <div class="goods-buyer">
                <span class="buyer">{{ orderItem.UserName }}</span>
                <!-- <span class="id">(用户id：{{ item }})</span> -->
                <span class="phone">{{ orderItem.UserPhone }}</span>
              </div>
            </div>

            <!-- 支付方式 -->
            <div class="td tr-goods-pay">
              <div class="goods-pay">
                <el-tag
                  v-if="orderItem.PayMethodText"
                  type="primary"
                  effect="dark"
                  >{{ orderItem.PayMethodText }}</el-tag
                >
              </div>
            </div>

            <!-- 配送方式 -->
            <div class="td tr-goods-express">
              <div class="goods-express">
                <el-tag
                  v-if="orderItem.DeliveryModeText"
                  type="primary"
                  effect="dark"
                  >{{ orderItem.DeliveryModeText }}</el-tag
                >
              </div>
            </div>

            <!-- 交易状态 -->
            <div class="td tr-goods-trade">
              <div class="goods-trade">
                <div>
                  <span>付款状态：</span>
                  <el-tag
                    :type="orderItem.PayMark ? 'success' : 'info'"
                    size="small"
                    effect="dark"
                    >{{ orderItem.PayMark ? "已付款" : "未付款" }}</el-tag
                  >
                </div>
                <div>
                  <span>发货状态：</span>
                  <el-tag
                    :type="orderItem.DeliveryMark ? 'success' : 'info'"
                    size="small"
                    effect="dark"
                    >{{ orderItem.DeliveryMark ? "已发货" : "未发货" }}</el-tag
                  >
                </div>
                <div>
                  <span>收货状态：</span>
                  <el-tag
                    :type="orderItem.ReceiptMark ? 'success' : 'info'"
                    size="small"
                    effect="dark"
                    >{{ orderItem.ReceiptMark ? "已收货" : "未收货" }}</el-tag
                  >
                </div>
              </div>
            </div>

            <!-- 订单状态 -->
            <div class="td tr-goods-status">
              <div class="goods-status">
                <el-tag
                  v-if="orderItem.StatusText"
                  :type="orderItem.Status == 90 ? 'info' : 'primary'"
                  :effect="orderItem.Status == 90 ? 'dark' : 'plain'"
                  >{{ orderItem.StatusText }}</el-tag
                >
              </div>
            </div>

            <!-- 操作 -->
            <div class="td tr-goods-control">
              <div class="control-box">
                <el-button
                  type="primary"
                  size="mini"
                  @click="onShowDetail(orderItem, 'detail')"
                  >订单详情</el-button
                >
                <el-button
                  v-if="
                    orderItem.Status == 20 &&
                      orderItem.DeliveryMode == 1 &&
                      orderItem.GrouponData != null &&
                      orderItem.GrouponData.Status == 2
                  "
                  type="success"
                  size="mini"
                  @click="onShowDetail(orderItem, 'detail')"
                  >去发货</el-button
                >
                <el-button
                  v-if="orderItem.Status == 28"
                  type="warning"
                  size="mini"
                  @click="onShowDetail(orderItem, 'verify')"
                  >去核销</el-button
                >
                <el-button
                  v-if="
                    orderItem.Status == 23 ||
                      (orderItem.Status == 24 && orderItem.RefundMark == 0)
                  "
                  type="danger"
                  size="mini"
                  @click="onShowDetail(orderItem, 'check')"
                  >去审核</el-button
                >
                <!-- <el-button
                  v-if="orderItem.Status == 24 && orderItem.RefundMark == 0"
                  type="warning"
                  size="mini"
                  plain
                  :disabled=refundDisabled
                  @click="refundCanceling(orderItem)"
                  >继续退款</el-button
                > -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-data">暂无数据</div>
    </div>
    <!-- end 订单表格 -->

    <div class="footer">
      <div class="total-info">
        <div>
          共<span>{{ totalRecord }}</span
          >张订单
        </div>
        <!-- <div>其中有效订单<span>22</span>个团，订单总额<span>¥{{ '2331' }}</span></div> -->
        <div>
          订单总额<span>¥{{ totalAmount }}</span>
        </div>
      </div>

      <!-- start 分页器 -->
      <el-pagination
        background
        :page-sizes="[10, 20, 30]"
        :page-size="submitData.page_size"
        :current-page="submitData.page_index"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRecord"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <!--end 分页器-->
    </div>

    <!-- 订单详情对话框 -->
    <GoodsDetailDialog
      ref="GoodsDetailDialog"
      @success="getOrderMaintList"
    ></GoodsDetailDialog>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";

import GoodsDetailDialog from "./components/goods-detail-dialog.vue";

export default {
  components: {
    GoodsDetailDialog,
  },

  data() {
    return {
      authType: "",
      tableMaxHeight: 0,
      totalRecord: 0,
      totalAmount: 0,
      multipleDate: [],
      submitData: {
        // business_guid: "",
        order_stores_guid: "",
        // take_stores_guid: "",
        status: 0,
        word: "",
        begin_date: "",
        end_date: "",
        page_index: 1,
        page_size: 10,
      },
      statusList: [],
      storeList: [],
      orderList: [],
      refundDisabled: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;

    this.multipleDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.onChangeDate();
    this.initOrderMaintList();
    this.getOrderMaintList(this.$route.query.order_id || 0);
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 初始化订单列表
    async initOrderMaintList() {
      try {
        let { data } = await Shop.initOrderMaintList();
        this.statusList = data.statusSelector;
        this.storeList = [
          { StoresGuid: "", StoresName: "全部" },
          ...data.storeSelector,
        ];
      } catch (err) {
        console.log(err);
      }
    },

    // 获取订单列表
    async getOrderMaintList(orderId = 0) {
      try {
        let { data } = await Shop.getOrderMaintList({
          ...this.submitData,
          order_id: orderId || "",
        });
        this.totalAmount = data.filter.TotalAmount;
        this.totalRecord = data.filter.TotalRecord;
        this.orderList = data.list;
      } catch (err) {
        console.log(err);
      }
    },

    //  // 退款
    //  async refundCanceling(event) {
    //   this.refundDisabled = true;
    //   try {
    //     let { errcode } = await Shop.refundCanceling({
    //       order_id: event.OrderId,
    //     });
    //     if (errcode == 0) {
    //       this.$message.success("已退款");
    //       this.getOrderMaintList()
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   } finally {
    //     this.refundDisabled = false;
    //   }
    // },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68);
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getOrderMaintList();
    },

    // 打开详情对话框
    onShowDetail(event, type) {
      this.$refs.GoodsDetailDialog.onShowDialog(event.OrderId, type);
    },

    // 日期改变时间
    onChangeDate() {
      this.submitData.begin_date = this.multipleDate[0];
      this.submitData.end_date = this.multipleDate[1];
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getOrderMaintList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getOrderMaintList();
    },

    goto(event) {
      let route = this.$router.resolve({
        path: "/shop/group/record",
        query: { groupon_id: event.GrouponData.GrouponId },
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.order {
  .df-order-table {
    width: 100%;
    height: auto;
    max-height: var(--max-height);
    overflow-y: auto;

    div,
    span {
      box-sizing: border-box;
    }

    .thead,
    .tbody {
      .flex-row;
      width: 100%;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;

      .order-item-list {
        .flex-row;
        flex: 4;
        align-items: unset;
      }
      .tr-goods-info {
        flex: 3;
      }
      .tr-goods-price {
        flex: 1;
      }
      .tr-goods-actual {
        flex: 1;
      }
      .tr-goods-buyer {
        flex: 1;
      }
      .tr-goods-pay {
        flex: 1;
      }
      .tr-goods-express {
        flex: 1;
      }
      .tr-goods-trade {
        flex: 1.5;
      }
      .tr-goods-status {
        flex: 1;
      }
      .tr-goods-control {
        flex: 1;
      }
    }

    .thead {
      position: sticky;
      top: 0;
      left: 0;
      background: white;
      border-bottom: 1px solid #ddd;
      z-index: 10;

      .tr .th {
        font-size: 15px;
        text-align: center;
        font-weight: bold;
      }
    }

    .thead .tr .th,
    .tbody .order-item .td > * {
      padding: 10px;
      box-sizing: border-box;
    }

    .thead .th,
    .tbody .td {
      border-top: 1px solid #ddd;
      border-left: 1px solid #ddd;
      // border-left: 1px solid #ddd;
    }

    .thead .tr:first-child .th,
    .tbody .tr .order-item .td:first-child {
      border-left: 0;
    }

    .tbody {
      .flex-col;
      .tr {
        .flex-col;
        width: 100%;

        > div {
          width: 100%;
        }

        .order-base {
          .flex-row;
          // justify-content: space-between;
          padding: 10px;
          border-top: 1px solid #ddd;

          span {
            margin-right: 10px;
          }

          .groupon-info {
            > span {
              // font-weight: bold
            }
          }
        }

        .order-item {
          .flex-row;
          align-items: unset;
          height: 100%;

          .td {
            > div {
              .flex-col;
              justify-content: center;
              height: 100%;
            }

            .goods-info {
              .flex-row;
              justify-content: unset;
              align-items: unset;

              .image {
                margin-right: 10px;
                .el-image {
                  width: 70px;
                  height: 70px;
                }
              }

              .info {
                .flex-col;

                span {
                  width: 100%;
                  line-height: 22px;
                }

                .name {
                  .text-overflow(2);
                }

                .attr {
                  .text-overflow(1);
                }
              }
            }

            .goods-info .attr,
            .goods-actual-amount .express,
            .goods-buyer .phone {
              color: #999;
            }

            .control-box {
              .flex-col;
              .el-button {
                width: 80px;
                margin: 0;
                margin-bottom: 5px;
              }
            }
          }

          .tr-goods-price,
          .tr-goods-actual,
          .tr-goods-buyer {
            span {
              line-height: 22px;
            }
          }
          .tr-goods-trade > div {
            > div {
              margin-bottom: 5px;
            }
          }
        }
      }

      &:last-child {
        border-bottom: 1px solid #ddd;
      }

      .tr:first-child .order-base {
        border-top: 0;
      }
    }
  }
  .no-data {
    .flex-row;
    justify-content: center;
    width: 100%;
    height: 100px;
    border: 1px solid #ddd;
    border-top: 0;
  }

  .footer {
    .flex-row;
    justify-content: space-between;
    margin-block-start: 20px;

    .total-info {
      .flex-row;
      > div {
        padding-inline: 10px;
        span {
          color: @primary-color;
          margin-inline: 5px;
        }
      }
    }
  }
}
</style>
